import { checkAuthToken, guestLogin, login } from '@/api'
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  // {
  //   path: '/about',
  //   name: 'About',
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/pixi',
    name: 'Pixi',
    component: () => import(/* webpackChunkName: "pixi" */ '../views/Pixi/index.vue')
  },
  {
    path: '/web',
    name: 'Web',
    component: () => import(/* webpackChunkName: "pixi" */ '../views/WebPixi/index.vue')
  },
  {
    path: '/category',
    name: 'Category',
    component: () => import(/* webpackChunkName: "category" */ '../views/Category/index.vue')
  },
  {
    path: '/product',
    name: 'Product',
    component: () => import(/* webpackChunkName: "product" */ '../views/Product/index.vue')
  },
  {
    path: '/cart',
    name: 'Cart',
    component: () => import(/* webpackChunkName: "cart" */ '../views/Cart/index.vue')
  },
  {
    path: '/three',
    name: 'Three',
    component: () => import(/* webpackChunkName: "three" */ '../views/Three/index.vue')
  },
  {
    path: '/error',
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ '../views/Error/index.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach(async(to, from, next) => {
  const isLoggedIn = checkAuthToken()
  if (isLoggedIn) {
    next()
  } else {
    try {
      // await login('PlugCustom-CN@example.com', 'password1');
      // await login('admin', '12345678');
      let username = localStorage.getItem('USER_NAME')
      const result = await guestLogin(username || undefined);
      localStorage.setItem('TOKEN', result.access_token)
      localStorage.setItem('USER_NAME', result.openId)
      localStorage.setItem('USER_ID', result.user_id)
      next()
    } catch(err) {
      console.log(err)
      // next('/error');
    }
  }
})

export default router
