import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
// 创建 Axios 实例
// console.log(process.env)
// export const baseURL = process.env.VUE_APP_API_BASE_URL
export  const baseURL = 'https://strapi.plugcustom.com.cn'
// export const baseURL = 'https://dev-strapi.plugcustom.com.cn'
// export const baseURL = 'http://120.24.79.229'
const instance: AxiosInstance = axios.create({
  baseURL,
  // baseURL: 'https://api.plugcustom.com.cn',
  // timeout: 10000,
});

// 用户认证 token
let authToken: string | null = null;
let userId: string | null = null;

// 登录中标志位
let isLoggingIn: boolean = false;

// 设置认证 token
function setAuthToken(token: string) {
  authToken = token;
}

// 检查认证 token 是否存在
export function checkAuthToken(): boolean {
  return authToken !== null;
}

// 获取认证 token
function getAuthToken(): string | null {
  return localStorage.getItem('TOKEN');
}

// 添加认证 token 到请求头
function setAuthHeader(config: AxiosRequestConfig): AxiosRequestConfig {
  console.log(getAuthToken(), '---22')
  if (checkAuthToken()) {
    config.headers = {
      Authorization: `Bearer ${getAuthToken()}`,
      // BB_ID: localStorage.getItem('BB_ID') || '1'
    }
  }
  return config;
}

// 更新 token
async function refreshToken(): Promise<void> {
  try {
    const response: AxiosResponse = await instance.post('/refresh-token', { token: getAuthToken() });
    const newToken: string = response.data.token;
    setAuthToken(newToken);
  } catch (error) {
    console.error('刷新 token 失败:', error.response.data);
    // 处理 token 刷新失败，例如跳转到登录页面
  }
}

instance.interceptors.request.use(async (config) => {
  if (!checkAuthToken()) {
    // await login('PlugCustom-CN@example.com', 'password1');
  }
  return config
}, (err) => {

})

// 监听响应，处理 token 失效并重新请求
instance.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  async (error) => {
    const originalRequest: any = error.config;
    if (error.response.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (!isLoggingIn) {
        // await refreshToken()
        await login('admin', '12345678');
        // originalRequest.headers = setAuthHeader(originalRequest.headers);
        
        return instance(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

// 发起 GET 请求
export async function get<T>(url: string, config: AxiosRequestConfig = {}): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance.get(url, setAuthHeader(config));
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// 发起 POST 请求
export async function post<T>(url: string, data: any, config: AxiosRequestConfig = {}): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance.post(url, data, setAuthHeader(config));
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// 发起 POST 请求
export async function del<T>(url: string, data: any, config: AxiosRequestConfig = {}): Promise<T> {
  try {
    const response: AxiosResponse<T> = await instance.delete(url, setAuthHeader(config));
    return response.data;
  } catch (error) {
    throw error.response.data;
  }
}

// 获取token
export async function login(username: string, password: string): Promise<void> {
  try {
    const response: AxiosResponse = await instance.post('/api/login', { username, password });
    authToken = response.data.data?.access_token;
    console.log(response.data.data)
    userId = response.data.data?.user_id;
    // console.log('成功:', response.data.data);
  } catch (error) {
    console.error('失败:', error.response.data);
  }
}
// guest登录
export async function guestLogin(username?: string, password?: string): Promise<any> {
  try {
    // const response: AxiosResponse = await instance.post('/api/users/guest-login', { username, password });
    const response: AxiosResponse = await instance.post('/api/system/temporaryLogin', { username });
    let { data } = response.data
    console.log(data)
    authToken = data.access_token;
    userId = data.openId;
    return data
  } catch (error) {
    console.error('失败:', error.response.data);
  }
}

// 使用示例
async function main(): Promise<void> {

  // 发起需要认证的请求
  // try {
  //   const responseData: any = await get('/protected-route');
  //   console.log('受保护的路由响应:', responseData);
  // } catch (error) {
  //   console.error('受保护的路由请求失败:', error);
  // }
}

main();
