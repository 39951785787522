import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css';
import './assets/font/iconfont.css'
import './assets/less/common.less'
import './assets/less/main.less'
// @ts-ignore
import Api from "./api";
import { createI18n } from 'vue-i18n';
import qs from 'qs'

// 引入语言文件
import zh_CN from './locales/cn.json';
import zh_TW from './locales/zh_cn.json';

const getCurrency = () => {
  const urlParse = qs.parse(window.location.href)
  const currency = (urlParse.currency as string) || window.localStorage.getItem('CURRENCY')
  if (currency) {
    window.localStorage.setItem('CURRENCY', currency)
    return currency
  }
}
getCurrency();

const getLocale = () => {
  const currency = window.localStorage.getItem('CURRENCY')
  switch (currency) {
    case 'HKD':
      return 'zh_TW'
    default:
      return 'zh_CN'
  }
}
const i18n = createI18n({
  legacy: false,
  locale: getLocale(), // 默认语言
  fallbackLocale: 'zh_TW', // 备用语言
  messages: {
    zh_CN,
    zh_TW,
  }
});

// import VConsole from 'vconsole';
// 本地开发调试注入vConsole
// if (process.env == 'development') { // 如果为开发模式, 则注入 vConsole, 预防正式会不小心忘记删除
  // new VConsole();
// }

const app = createApp(App)
app.use(i18n);
app.config.globalProperties.$request = Api; // 全局挂载
app.use(store).use(router).mount('#app')
